import { Component } from 'react'
import './Friends.css'
import '../../css/Placeholder.css'
import '../../css/Modal.css'
import { copyTextToClipboard } from '../utils'
import { Menu } from "../../components/Menu"
import { format } from '../utils'
import { TGData, TG } from "../../telegramContext"
import { BluePopup } from "../popups/popups_component"



interface ModalFriendsProps {
  type: string
  userData: any
  friends: number
  updateView: (view:string) => void
  onClose: () => void
}


export const ModalFriends = ({
  type = '',
  userData,
  friends = 0,
  updateView,
  onClose,
}: ModalFriendsProps) => {
  var header_content: any = ''
  var title: any = ''
  var content: any = ''
  var footer: any = ''
  
  if (type === '') {
    return <></>
  }

  var eggs_to_buy = new Map()
  eggs_to_buy.set("1", {"price":"10"})
  eggs_to_buy.set("3", {"price":"20"})
  eggs_to_buy.set("5", {"price":"30"})
  eggs_to_buy.set("8", {"price":"50"})
  eggs_to_buy.set("10", {"price":"80"})

  function forwardToLink() {
    window.open(`https://t.me/share/url?url=https://t.me/egg_play_bot/app?startapp=${TGData.id}`)
  }

  if (type === "friends") {
    if (friends === 0) {
      title = "СПИСОК ДРУЗЕЙ ПУСТ"
      content = <><div className="add_friend_elem">
      <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
      <div className='friends_actions_text_elem' onClick={() => forwardToLink()}>
        <div className='friends_actions_text'>
          ПРИГЛАСИТЬ ДРУГА
        </div>
      </div>
    </div></>
    } else {
      title = "СПИСОК ДРУЗЕЙ"
      var friends_content: any = []
      Object.entries(userData.friends).forEach(([k, v]:[string, any]) => {
        friends_content.push(<>
          <div className="friends_elem">
            <div className="friends_left_block">
              <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>

              {v["invited_users_count"]}
            </div>
            <div className="friends_center_block">
              {k} ЛИНИЯ
            </div>
            <div className="friends_right_block">
              <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>

              {(v["total_earned_value"]) ? (`+${format(v["total_earned_value"])}`) : (0)}
            </div>
          </div>
        </>)
      });
      content = <>
        <div className="friends_elems">
          {friends_content.map((object:any, i:any) => <>{object}</>)}
        </div>
        </>
      
    }
  } else if (type === "buy_friends") {
    title = "КУПИТЬ EGG ДРУГА"
    content = <>
    <div className="buy_friend_elems">

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_1")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            1 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 10
          </div>
        </div>
      </div>

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_3")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            3 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 20
          </div>
        </div>
      </div>

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_5")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            5 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 30
          </div>
        </div>
      </div>

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_8")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            8 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 50
          </div>
        </div>
      </div>

      <div className="buy_friend_elem" onClick={() => updateView("buy_friends_10")}>
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            10 EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ 80
          </div>
        </div>
      </div>
    </div>
    </>
  } else if (type.match("buy_friends_[0-9]{1,2}")) {
    // @ts-ignore
    var eggs_quantity: string = type.match('[0-9]{1,2}')[0]
    
    title = "КУПИТЬ EGG ДРУГА"
    content = <>
    <div className="buy_friends_checkout">

      <div className="buy_friend_elem item">
        <div className="buy_friend_left">
          <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
            {eggs_quantity} EGG
        </div>
        <div className="buy_friend_right">
          <div className="buy_friend_price">
            $ {eggs_to_buy.get(eggs_quantity).price}
          </div>
        </div>
      </div>
      
      <div className="buy_friends_checkout_title">
        ВЫБЕРИТЕ СПОСОБ ОПЛАТЫ
      </div>
      
      <div className="buy_friend_checkout_buttons">

        <div className="buy_friend_checkout_button">
          <div className="buy_friend_left">
            <div className="buy_friend_checkout_title">
              <div className="buy_friend_checkout_main">
                Оплатить TON
              </div>
            </div>
          </div>
          <div className="buy_friend_right">
            <div className="buy_friend_checkout_pic ton">
              {/* <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg> */}
            </div>
          </div>
        </div>
        
        <div className="buy_friend_checkout_button">
          <div className="buy_friend_left">
            <div className="buy_friend_checkout_title">
              <div className="buy_friend_checkout_main">
                Оплатить картой
              </div>
              <div className="buy_friend_checkout_desc">
                Visa, Mastercard
              </div>
            </div>
          </div>
          <div className="buy_friend_right">
            {/* <div className="buy_friend_checkout_pic visa"> */}
              <svg className='buy_friend_checkout_pic visa' xmlns="http://www.w3.org/2000/svg"></svg>
            {/* </div> */}
            {/* <div className="buy_friend_checkout_pic mastercard"> */}
              <svg className='buy_friend_checkout_pic mastercard' xmlns="http://www.w3.org/2000/svg"></svg>
            {/* </div> */}
          </div>
        </div>

      </div>

    </div>
    </>

    footer = <><div className='buy_friend_footer'>
      <div className="buy_friend_back" onClick={() => updateView("buy_friends")}>
        НАЗАД
      </div>
    </div></>
  }
  
  return (
    <>
     <div className='modal' onClick={onClose}>
      {/* @ts-ignore */}
      <div className={(friends === 0) ? ('modalFriends-dialog empty '+type.match('buy_friends|friends')[0]) : ('modalFriends-dialog '+type.match('buy_friends|friends')[0])} onClick={e => e.stopPropagation()}>
        <div className='modalFriends-header'>
          <span className='modal-close' onClick={onClose}>
            &times;
          </span>
          {/* @ts-ignore */}
          <svg className={"modalFriends__img "+type.match('buy_friends|friends')[0]} xmlns="http://www.w3.org/2000/svg"></svg>
          <div className='modalFriends-title'>{title}</div>
          {/* {title_button && <div className='modalFriends-title_button'>{title_button}</div>} */}
          {header_content && <div className='modalFriends-header_content'>{header_content}</div>}

        </div>
        <div className='modalFriends-body'>
          <div className='modalFriends-content'>{content}</div>
        </div>
        {footer && <div className='modalFriends-footer'>{footer}</div>}
      </div>
    </div>
    </>
  )

}


export default class FRIENDS_SCREEN extends Component<{userData: any, updateView: (view: string) => void}, {}> {
  state = {
    screenView: '',
    popupData: {visible: false, text: ''}
  }

  changeModalView = (view: string) => {
    this.setState({
      screenView: view
    })
  }

  getFriendsTotal = () => {
    var friendsList: number = 0
    var dat = Object.values(this.props.userData.friends);
    console.log(this.props.userData.friends)
    dat.forEach((lined: any) => {
      friendsList+=lined["invited_users_count"]
    });
    return friendsList
  }

  forwardToLink = () => {
    window.open(`https://t.me/share/url?url=https://t.me/egg_play_bot/app?startapp=${TGData.id}`, '_blank', 'noopener,noreferrer')
  }

  openPopup = (text: any) => {
    this.setState({
        popupData: {visible: true, text: text}
      })
    setTimeout(
      () => this.setState({
        popupData: {visible: false, text: ''}
      }), 3000
    )
  }

  ToClipboardWrapper = (text_to_copy: string) => {
    this.openPopup("Ссылка-Приглашение скопирована")
    copyTextToClipboard(text_to_copy)
  }


  friendsTotalAmount = this.getFriendsTotal()
  render() {
    return (
        <>
          <div className="friends__block">
            <div className="friends_top_block">
              <div className="friends_reward_elems">
                <div className="friends_reward_elem friend">
                  <div className="friends_reward_first_icon">
                    <div className='icon'></div>
                  </div>
                  <div className='friends_reward_text_elem'>
                    <div className='friends_reward_text'>
                      Пригласите<br/>друга
                    </div>
                    <div className='friends_reward_price'>
                      <div className='friends_reward_icon'>
                        <svg xmlns="http://www.w3.org/2000/svg"></svg>
                      </div>
                      <div className='friends_reward_price_value'>
                        + {format(10000)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="friends_reward_elem premium">
                  <div className="friends_reward_first_icon">
                    <div className='icon'></div>
                  </div>

                  <div className='friends_reward_text_elem'>
                    <div className='friends_reward_text'>
                    Пригласите 3 друга<br/>с Telegram Premium
                    </div>
                    <div className='friends_reward_price'>
                      <div className='friends_reward_icon'>
                        <svg xmlns="http://www.w3.org/2000/svg"></svg>
                      </div>
                      <div className='friends_reward_price_value'>
                        + {format(500000)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="friends_actions_elems">
                <div className="friends_actions_elem first" onClick={() => this.forwardToLink()}>
                  <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
                  <div className='friends_actions_text_elem'>
                    <div className='friends_actions_text'>
                      ПРИГЛАСИТЬ ДРУГА
                    </div>
                  </div>
                </div>

                <div className="friends_actions_elem second" onClick={() => this.ToClipboardWrapper(`https://t.me/egg_play_bot?startapp=${TGData.id}`)}>
                  <svg className='icon' xmlns="http://www.w3.org/2000/svg"></svg>
                </div>
                
              </div>
            </div>

            <div className="friends_bottom_block">
              <div className="friends_bottom_elem first" onClick={() => this.changeModalView('friends')}>
                <div className="fb_left__block">
                  <div className="fb_icon"></div>
                  <div className="fb_text__block">
                    <div className="fb_title">
                      МОИ ДРУЗЬЯ
                    </div>
                  </div>
                </div>
                <div className="fb_right__block">
                  <div className="fb_friends_amount">
                    {this.friendsTotalAmount}
                  </div>
                  <div className="fb_action">
                    <svg xmlns="http://www.w3.org/2000/svg"></svg>
                  </div>
                </div>
              </div>
              <div className="friends_bottom_elem second" onClick={() => this.changeModalView('buy_friends')}>
                <div className="fb_left__block">
                  <div className="fb_icon"></div>
                  <div className="fb_text__block">
                    <div className="fb_title">
                      КУПИТЬ ВИРТУАЛЬНОГО ДРУГА
                    </div>
                  </div>
                </div>
                <div className="fb_right__block">
                  
                  <div className="fb_action">
                    <svg xmlns="http://www.w3.org/2000/svg"></svg>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <BluePopup visible={this.state.popupData.visible} text={this.state.popupData.text}/>
          <ModalFriends 
            type={this.state.screenView}
            userData={this.props.userData}
            friends={this.friendsTotalAmount}
            updateView={this.changeModalView}
            onClose={() => this.changeModalView('')}
          />
          <Menu updateView={() => this.props.updateView} activeScreen={'FRIENDS'}/>
        </>
      )
    }
}