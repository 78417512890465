import { useState } from 'react'
import './Tasks.css'
import '../../css/Placeholder.css'
import { format } from '../utils'
import { Modal } from '../EGG/egg_page'
import { api } from '../../App'
// import TASKSDATA from '../TasksData.json'
import cn from 'classnames'
import { TGData, TG } from "../../telegramContext"
import { CongratsCoins } from '../congrats/congrats_component'

interface TASKProps {
  task_id: string
  userData: any
  readiness: any
  data: any
  changeModalView: (view: string) => void
}

const TASK = ({
  task_id = '',
  userData,
  readiness = '',
  data = {},
  changeModalView
}:TASKProps): JSX.Element => {
  if (task_id === "task_dailyreward") {
    var date = new Date();
    var now = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0));
    var tomorrow = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59));
    var CanCollect = true
    if (Number(now.getTime())/1000 <= userData.last_daily_bonus_collection_timestamp && userData.last_daily_bonus_collection_timestamp < Number(tomorrow.getTime())/1000) {
      CanCollect = false
    }
    if (!CanCollect) {
      readiness = "done"
    }
  }
  return (<>
      <div className={cn("task_elem", readiness, data.type)} onClick={() => changeModalView(task_id)}>
        <div className="task_left__block">
          <div className="task_icon"></div>
          <div className="task_text__block">
            <div className="task_title">
              {data.title}
            </div>
            <div className="task_profit">
              <div className="coin"></div>
              <div className="value">+{format(data.reward)}</div>
            </div>
          </div>
        </div>
        <div className="task_action">
          <svg xmlns="http://www.w3.org/2000/svg"></svg>
          {/* <div className="task_action_icon"></div> */}
        </div>
      </div>
  </>)
}


interface ModalTaskProps {
  taskId: string
  taskData: any
  readiness: string
  userData: any
  guess: string
  onClose: () => void
  doneTask: (task_id: string, block_id:string) => void
  checkBybit: (taskId: string, block_id: string, value: string) => void
  checkGuess: (value: string, block_id:string) => void
}

export const ModalTask = ({
  taskId = '',
  taskData,
  readiness = '',
  userData,
  guess,
  onClose,
  doneTask,
  checkBybit,
  checkGuess
}: ModalTaskProps) => {
  var header_content: any = ''
  var title: any = taskData.title
  var content: any = ''
  var content_buttons: any = ''
  var footer: any = ''


  const [rewardStatus, setRewardStatus] = useState('');
  const [guessText, setGuessText] = useState('');
  const [bybitModal, setBybitModal] = useState('');
  const [bybitText, setBybitText] = useState('');


  function bybitUpdateModal(modal:string) {
    setBybitModal(modal)
  }

  function guesschangefunc(event:any) {
    setGuessText(event.target.value)
  }

  function bybitchangefunc(event:any) {
    setBybitText(event.target.value)
  }

  function toCheckBybit() {
    checkBybit(taskId, taskData.block_id, bybitText)
  }

  function forwardToLink(link:string) {
    if (taskId === "task_bybit") {
      setBybitModal("inputUID")
    } else {
      setTimeout(() => setRewardStatus("ok"), 2000)
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  }

  // console.log(taskData.type)
  if (taskData.type === "task_guess") {
    if (readiness === "ready") {
      content_buttons = <><div className='modalTaskInput_block'>
      <input onChange={guesschangefunc} className={'modalTaskInput '+guess} placeholder='Введите ответ...'></input>
      {(guess) ? <><div className={"modalTaskInput_status "+guess}><div className='icon'></div></div></> : <></>}
    </div>
    <div className='modalTaskButton_block'>
      <div className='modalTaskButtons_elem'>
      {(guess === "checked") ? <><div className='modalTaskButton checked' onClick={() => checkGuess(guessText, taskData.block_id)}>
          ЗАБРАТЬ НАГРАДУ
        </div></> : <><div className='modalTaskButton check guess' onClick={() => checkGuess(guessText, taskData.block_id)}>
          ПРОВЕРИТЬ
        </div></>}
      </div>
    </div></>
    }
    content = <><div className='modalTaskReward_block'><svg className="modalTask__coin" xmlns="http://www.w3.org/2000/svg"></svg><div className='modalTaskReward__value'>+ {format(taskData.reward)}</div></div>
      <div className='modalTaskText_block'>
        {taskData.desc}
      </div>
      <div className='modalTaskDesc_block'>
        Ответ вводите на английском. Если вам сложно, обратитесь к комьюнити EGG
      </div>
    </>
  
  } else if (["task_telegram", "task_youtube", "task_x", "task_discord"].includes(taskData.type)) {
    // if (readiness === "ready") {
    content_buttons = <>
      <div className='modalTaskButtons_block'>
        <div className='modalTaskButtons_elem'>
            <div className='modalTaskButton action' onClick={() => forwardToLink(taskData.url)}>
            {taskData.button_text}
            </div>
          </div>
          <div className='modalTaskButtons_elem'>
            <div {...((rewardStatus === "ok") ? ({className: 'modalTaskButton check ok', onClick:() => doneTask(taskId, taskData.block_id)}) : ({className: 'modalTaskButton check'}))}>
              ПРОВЕРИТЬ
            </div>
        </div>
      </div>
    </>
    // }
    content = <><div className='modalTaskReward_block'><svg className="modalTask__coin" xmlns="http://www.w3.org/2000/svg"></svg><div className='modalTaskReward__value'>+ {format(taskData.reward)}</div></div></>

  } else if (taskData.type === "task_bybit") {
    title = <>Зарегистрироваться<br/>на ByBit</>
    if (readiness === "done") {
       content_buttons = <><div className='modalTaskDesc_elems'>
            <div className='modalTaskDesc_block first'>
              Создайте новый аккаунт на криптовалютной<br/>бирже ByBit, используя кнопку ниже.
            </div>
            <div className='modalTaskDesc_block second'>
              После регистрации введите ваш UID<br/>в следующее поле. Вы можете найти UID<br/>в вашем профиле на ByBit.
            </div>
          </div></>
    }

    if (userData.BybitUID && readiness === "pending") {
      content_buttons = <><div className='modalTaskButtons_block'>
      <div className='modalTaskDesc_elems'>
          <div className='modalTaskDesc_block first '>
            Ваш ByBit UID на проверке...
          </div>
        </div>
        <div className='modalTaskButton waiting'>
          ОЖИДАЙТЕ ПОДТВЕРЖДЕНИЯ
        </div>
    </div></>
    } else if (userData.BybitUID && userData.BybitStatus === "Checked") {
      content_buttons = <><div className='modalTaskButtons_block'>
      <div className='modalTaskDesc_block second'>
        Ваша регистрация подтверждена!
      </div>
      <div className='modalTaskButton check okBYBIT' onClick={() => doneTask(taskId, taskData.block_id)}>
        ЗАБРАТЬ НАГРАДУ
      </div>
    </div></>
    } else {
      if (!bybitModal) {
        content_buttons = <>
        <div className='modalTaskDesc_elems'>
          <div className='modalTaskDesc_block first'>
            Создайте новый аккаунт на криптовалютной<br/>бирже ByBit, используя кнопку ниже.
          </div>
          <div className='modalTaskDesc_block second'>
            После регистрации введите ваш UID<br/>в следующее поле. Вы можете найти UID<br/>в вашем профиле на ByBit.
          </div>
        </div>

        <div className='modalTaskButtonsBybit_elem'>
            <div className='modalTaskButton check ok' onClick={() => bybitUpdateModal("inputUID")}>
                  ЗАРЕГИСТРИРОВАТЬСЯ
            </div>
        </div></>
      } else if (bybitModal === "inputUID") {
        content_buttons = <>
        <div className='modalTaskDesc_elems'>
          <div className='modalTaskDesc_block first'>
            Введите ваш UID ByBit
          </div>
        </div>
        <div className='modalTaskBybitInput_block'>
          <div className='modalTaskInput_block'>
            <input onChange={bybitchangefunc} className={'modalTaskInput'} placeholder='Bybit UID'></input>
          </div>
        </div>
        <div className='modalTaskButtonsBybit_elem'>
          <div className='modalTaskButtons_elem'>
            <div className='modalTaskButton check ok' onClick={() => toCheckBybit()}>
                ПОДТВЕРДИТЬ
            </div>
          </div>
        </div></>
      }
    }
    
    content = <><div className='modalTaskReward_block'><svg className="modalTask__coin" xmlns="http://www.w3.org/2000/svg"></svg><div className='modalTaskReward__value'>+ {format(taskData.reward)}</div></div></>
  }
  var func = <></>

  if (readiness === "done") {
    content_buttons = <><div className='modalTaskButtons_block'>
      {func}
      <div className='modalTaskButton done'>
        ЗАДАНИЕ ВЫПОЛНЕНО
      </div>
    </div></>
  }

  return (<>
    <div className='modal' onClick={onClose}>
      <div className={'modalTask-dialog '+taskData.type} onClick={e => e.stopPropagation()}>
        <div className='modal-header'>
          <span className='modal-close' onClick={onClose}>
            &times;
          </span>
          <svg className="modalTask__img" xmlns="http://www.w3.org/2000/svg"></svg>
          <div className='modalTask-title'>{title}</div>
          {/* {title_button && <div className='modalTask-title_button'>{title_button}</div>} */}
          {header_content && <div className='modalTask-header_content'>{header_content}</div>}

        </div>
        <div className='modalTask-body'>
          <div className='modalTask-content'>{content}
          {content_buttons}</div>
        </div>
        {footer && <div className='modalTask-footer'>{footer}</div>}
      </div>
    </div>
    </>)
}


export default function TASKS({userData, addTaskDone, addBybit, openCongrats}:{userData:any, addTaskDone: (balance:number, statuses:any) => void, addBybit: (bybit_id:string, tasks_statuses: any) => void, openCongrats: () => void}) {
  const [[ModalView, ModalTaskReadiness], setModalView] = useState(['', '']);
  const [guessStatus, setGuessStatus] = useState('');
  const [choosenTaskId, setChoosenTaskId] = useState('');

  var checkReady: any = []

  const changeModalView = (task_id:string) => {
    var TaskReadiness: any = 'ready'
    if (task_id) {
      var ud = Object.entries(userData.tasks).filter(([k, v]) => k === task_id);
      // var ud = userData.tasks.filter((data:any) => data.id === task_id)[0]
      if (ud.length > 0) {
        TaskReadiness = ud[0][1]
      }
    }
    setChoosenTaskId(task_id)
    setModalView([task_id, TaskReadiness])
  }
  
  const tasks_blocks: any = []
  var AllTasks: any = {}


  Object.entries(userData.all_tasks).forEach(([k_block, v_block]):any => {
    if (k_block !== "block_default") {

      var vb: any = v_block

      var tasks_content: any = []
      Object.entries(vb.tasks).forEach(([task_id, task_data]) => {
        var td: any = task_data
        AllTasks[task_id] = td
        console.log(userData.tasks)
        tasks_content.push(<>
            <TASK task_id={task_id} userData={userData} readiness={(userData.tasks[td.type] ? userData.tasks[td.type] : 'ready')} data={task_data} changeModalView={changeModalView}/>
        </>)
      })
      var blocks_data = <>
        <div className='base_tasks_title'>
          {vb.title}
        </div>
        <div className="base_tasks">
          {tasks_content.map((object:any, i:any) => <>{object}</>)}
        </div>
      </>
      tasks_blocks.push(blocks_data)
    }
  });


  const doneTask = (taskId: string, block_id: string) => {
    api.post("/api/task/verify", {
      "filter": {
        "id": TGData.id,
      },
      "payload": {
        "block_id":block_id,
        "task_id": taskId,
        "task_type": AllTasks[taskId].type,
        "balance_increase": userData.sync_earnings
      }
      }).then( res => {
        console.log(res.data)
        if (res.data.success) {
          setModalView([taskId, 'done'])
          addTaskDone(res.data.current_balance, res.data.tasks_statuses)
          openCongrats()
        }
     }).catch(err => {

     })
    
  }

  function checkBybit (taskId: string, block_id: string, value: string) {
    if (value.length < 6) {
      return "failed"
    } else {
      // axios
      api.post("/api/task/verify", {
        "filter": {
          "id": TGData.id,
        },
        "payload": {
          "block_id":block_id,
          "task_id": taskId,
          "task_type": AllTasks[taskId].type,
          "answer": value
        }
        }).then( res => {
          console.log(res.data)
          if (res.data.success) {
            console.log(res.data.tasks_statuses)
            addBybit(value, res.data)
            setModalView([taskId, 'pending'])
          }
       })
      // Сохранить bybit uid
      
    }
  }

  const checkGuess = (value: string, block_id: string) => {
    if (guessStatus === "checked") {
      setGuessStatus('')
      setModalView(['task_guess', 'done'])
    }

    if (value.length < 2) {
      setGuessStatus('failed')
    } else {
      // axios
      api.post("/api/task/verify", {
        "filter": {
          "id": TGData.id
        },
        "payload": {
          "block_id": block_id,
          "task_id": "task_guess",
          "answer": value
        }
        }).then( res => {
          console.log(res.data)
          if (res.data.success) {
            setGuessStatus('checked')
            addTaskDone(res.data.current_balance, res.data.tasks_statuses)
            openCongrats()
          } else {
            setGuessStatus('failed')
          }
        }).catch( err => {
            console.log(err)
            setGuessStatus('failed')
        })
    }
  }

  return (
    <>
    <div className="tasks_back_block">
      <div className="tasks_block">
        <div className="tasks_header">
          <div className="icon"></div>
        </div>
        {tasks_blocks.map((object:any, i:any) => <>{object}</>)}
      </div>
    </div>
    {(ModalView === 'task_dailyreward') ? (<Modal type={"DailyReward"} visible={true} userData={userData} onClose={() => changeModalView('')} updateUser={() => changeModalView('')}/>) : (ModalView) ? (<ModalTask taskId={choosenTaskId} taskData={AllTasks[choosenTaskId]} readiness={ModalTaskReadiness} userData={userData} guess={guessStatus} onClose={() => changeModalView('')} doneTask={doneTask} checkBybit={checkBybit} checkGuess={checkGuess}/> ) : (<></>)}
    </>
  )
}




// export default function TASKS_SCREEN({updateView}: {updateView: () => void})  {
//   return (
//       <>
//         <div className="placeholder__block">
//           <div className="placeholder__text">
//             Скоро откроется
//           </div>
//         </div>
//         <Menu updateView={updateView} activeScreen={'TASKS'}/>
//       </>
//     )
//   }