import { useState } from 'react'
import './Upgrade.css'
import './upgrade_imgs.css'
import '../EGG/Egg.css'
import '../../css/Placeholder.css'
import { format, format_decs} from '../utils'
import SkillsUpgrades from "../SkillsUpgrades.json"
import { api } from '../../App'
import { TGData, TG } from "../../telegramContext"



interface CongratsComboProps {
  visible: any
  comboData: any
  comboCount: number
  onClose: () => void
}

const CongratsCombo = ({visible, comboData, comboCount, onClose}:CongratsComboProps) => {
  if (!visible) return <></>

  if (comboCount !== 3) {
    return (<>
    <div className='modal' onClick={onClose}>
      <div className={'modal-combo_dialog'} onClick={e => e.stopPropagation()}>
        <div className='modal-header'>
          <span className='modal-close' onClick={onClose}>
            &times;
          </span>
          <div className={"modal_up_card__img combo_congrats_pic"}></div>
          <div className='modal_up_card_grats-title'>{(comboCount === 1) ? ("КРУТО, ПЕРВАЯ КАРТА ЕСТЬ! НЕ ОСТАНАВЛИВАЙСЯ!") : ("КРУТО, ВТОРАЯ КАРТА ЕСТЬ! НЕ ОСТАНАВЛИВАЙСЯ!")}</div>
          <div className='modal_up_card-lvl_elem'>
            <div className='modal_up_card-lvl_text'>КОМБО:</div>
            <div className='modal_up_card-lvl_value'>{comboCount} / 3</div>
          </div>
        </div>
          <div className='modal-combo_footer'>
            <div className="modal-combo_footer__button">
              <div className='modal-combo_get__button' onClick={onClose}>УРА!</div>
            </div>
          </div>
      </div>
    </div>
  </>)
  } else {
    return (<>
      <div className='modal' onClick={onClose}>
        <div className={'modal-combo_dialog'} onClick={e => e.stopPropagation()}>
          <div className='modal-header'>
            <span className='modal-close' onClick={onClose}>
              &times;
            </span>
            <div className={"modal_up_card__img combo_congrats_pic"}></div>
            <div className='modal_up_card_grats-title'>{"КОМБО СОБРАНО И ВЫ ВОЗНАГРАЖДЕНЫ!"}</div>
            <div className='modal_up_card_grats-reward_elem'>

                <svg xmlns="http://www.w3.org/2000/svg"></svg>
                  +
                <div className='modal-combo_profit__value'>
                  {format_decs(comboData)}
                </div>
            </div>
            <div className='modal_up_card-grats_elem'>
              <div className='modal_up_card-grats_text'>КОМБО:</div>
              <div className='modal_up_card-grats_value'>{comboCount} / 3</div>
            </div>
          </div>
            <div className='modal-combo_footer'>
              <div className="modal-combo_footer__button">
                <div className='modal-combo_get__button grats' onClick={onClose}>ЗАБРАТЬ!</div>
              </div>
            </div>
        </div>
      </div>
    </>)
  }
}


interface CongratsCoinsProps {
  visible: boolean
  onClose: () => void
}


const CongratsCoins = ({visible, onClose}:CongratsCoinsProps) => {
  if (!visible) return <></>
  setTimeout(onClose, 500)
  return (
<>
    <div className='grats_wrap'>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x:":"-0vw)", "--coin-to-y": "-32vw", "--coin-delay": "0s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"-5vw)", "--coin-to-y": "-40vw", "--coin-delay": "0s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"-vw)", "--coin-to-y": "-35vw", "--coin-delay": "0s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"2vw", "--coin-to-y": "-30vw", "--coin-delay": "0s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"9vw", "--coin-to-y": "-26vw", "--coin-delay": "0.05s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"17vw", "--coin-to-y": "-34vw", "--coin-delay": "0s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"26vw", "--coin-to-y": "-30vw", "--coin-delay": "0.04s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"40vw", "--coin-to-y": "-22vw", "--coin-delay": "0s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"0vw)", "--coin-to-y": "-35vw", "--coin-delay": "0.06s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"-7vw)", "--coin-to-y": "-31vw", "--coin-delay": "0.1s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"10vw", "--coin-to-y": "-41vw", "--coin-delay": "0s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"21vw", "--coin-to-y": "-38vw", "--coin-delay": "0.05s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"46vw", "--coin-to-y": "-37vw", "--coin-delay": "0.0s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"38vw", "--coin-to-y": "-20vw", "--coin-delay": "0.04s"} as React.CSSProperties}
      ></div>
      <div
        className="grats_coin grats_coin--animated"
        style={{"--coin-to-x":"47vw", "--coin-to-y": "-45vw", "--coin-delay": "0s"} as React.CSSProperties}
      ></div>
    </div>
</>
    )
}


interface ModalProps {
  upgradeID: string
  visible: boolean
  userData: any
  state: any
  onSubmit: (upgradeID:string) => void
  onClose: () => void
}

const UpgradeModal = ({
  upgradeID = '',
  visible = false,
  userData = '',
  state = '',
  onSubmit,
  onClose,
}: ModalProps): JSX.Element => {

  if (!visible) return <></>

  var upgradeUserData: any = (Object.entries(userData.upgrades).filter(([k, v]) => k === upgradeID));
  var level = 0
  if (upgradeUserData.length !== 0) {
    level = upgradeUserData[0][1]
  }
  
  console.log(level)
  var DT = SkillsUpgrades.filter( function(data){ return data.id === upgradeID });
  if (level === DT[0]["levels"].length) return <></>
  
  return (
    <>
    <div className='modal' onClick={onClose}>
      <div className={'modal-combo_dialog'} onClick={e => e.stopPropagation()}>
        <div className='modal-header'>
          <span className='modal-close' onClick={onClose}>
            &times;
          </span>

          <div className={"modal_up_card__img "+DT[0].id}></div>
          <div className='modal_up_card-title'>{DT[0].name}</div>
          <div className='modal_up_card-lvl_elem'>
            <div className='modal_up_card-lvl_text'>УРОВЕНЬ:</div>
            <div className='modal_up_card-lvl_value'>{level}</div>
          </div>
          <div className='modal_up_card-description'>{DT[0].desc}</div>
        </div>
        <div className='modal-body'>
          <div className='modal-content'>
            <div className='modal-combo_profit__block'>
              <div className='modal-combo_profit__text'>
                ПРИБЫЛЬ В ЧАС
              </div>
              <div className='modal-combo_profit__elem'>
                <svg xmlns="http://www.w3.org/2000/svg"></svg>
                +
                <div className='modal-combo_profit__value'>
                  {format_decs(DT[0]["levels"][level]["profit"])}
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className='modal-combo_footer'>
            <div className="modal-combo_footer__button">
              <div className="modal-combo__value_elem">
                <svg xmlns="http://www.w3.org/2000/svg"></svg>
                <div className="modal-combo__reward_value">
                  {format(DT[0]["levels"][level]["price"])}
                </div>
              </div>

              <div {...((userData.balance >= DT[0]["levels"][level]["price"]) ? ({onClick:() => onSubmit(upgradeID), className:"modal-combo_get__button"}) : ({className:"modal-combo_get__button disabled"}) )}>
                {(userData.balance >= DT[0]["levels"][level]["price"]) ? ('УЛУЧШИТЬ') : ('Недостаточно средств')}
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  )
}



interface UPGRADEprops {
  balance: number
  userData: any
  buyUpgrade: (res:any) => void
  openCongrats: () => void
}

export default function UPGRADE({balance, userData, buyUpgrade, openCongrats}:UPGRADEprops): JSX.Element  {
  const upgradeData: any = []
  const [ModalView, setModalView] = useState({
    modalIsOpen: false,
    modalView: '',
    mainView: 'first',
    congratsCombo: false
  })

  const [comboData, setComboData] = useState('')


  const openModal = (modalView:string) => {
    setModalView({
      modalIsOpen: true,
      modalView: modalView,
      mainView: ModalView.mainView,
      congratsCombo: false
    })
  };

  const closeModal = () => {
    setModalView({
      modalIsOpen: false,
      modalView: '',
      mainView: ModalView.mainView,
      congratsCombo: false
    })
  };

  const closeCongrats = () => {
    setModalView({
      modalIsOpen: ModalView.modalIsOpen,
      modalView: ModalView.modalView,
      mainView: ModalView.mainView,
      congratsCombo: ModalView.congratsCombo
    })
  };

  const updateView = () => {
    setModalView({
      modalIsOpen: ModalView.modalIsOpen,
      modalView: ModalView.modalView,
      mainView: ModalView.mainView,
      congratsCombo: ModalView.congratsCombo
    })
  };

  const updateMainView = (view:string) => {
    setModalView({
      modalIsOpen: ModalView.modalIsOpen,
      modalView: ModalView.modalView,
      mainView: view,
      congratsCombo: ModalView.congratsCombo
    })
  };

  const submitButton = (upgradeID:string) => {
    var DT = SkillsUpgrades.filter( function(data){ return data.id === upgradeID });

    if (DT === undefined) {
      console.log('ERROR')
      setModalView({
        modalIsOpen: false,
        modalView: '',
        mainView: ModalView.mainView,
        congratsCombo: false
      })
      return
    }

    var upgradeUserData: any = (Object.entries(userData.upgrades).filter(([k, v]) => k === upgradeID));
    var level = 0
    if (upgradeUserData.length !== 0) {
      level = upgradeUserData[0][1]
    }

    if (level === DT[0].levels.length) {
      console.log('ERROR')
      setModalView({
        modalIsOpen: false,
        modalView: '',
        mainView: ModalView.mainView,
        congratsCombo: false
      })
      return
    }

    if (userData.balance < DT[0].levels[level].price) {
      console.log('ERROR')
      setModalView({
        modalIsOpen: false,
        modalView: '',
        mainView: ModalView.mainView,
        congratsCombo: false
      })
    } else {
      var out = ''
      api.post('/api/store/upgrade', {
        "filter":{
          "id": TGData.id
          },
        "values":{
          "upgrade_id": upgradeID,
          // "balance": userData.balance,
          "upgrade_class":"MINING",
          "balance_increase":userData.sync_earnings
        }
        }).then(res => {
          console.log(res.data)
          buyUpgrade(res)
          if (res.data.has_collected_combo_part) {
            if (res.data.daily_combo_collected.length === 3) {
              setComboData(res.data.combo_reward)
            } else {
              setComboData("GratsCombo")
            }
          }
          openCongrats()
          setModalView({
            modalIsOpen: false,
            modalView: '',
            mainView: ModalView.mainView,
            congratsCombo: res.data.has_collected_combo_part
          })
        }).catch(err => {
          console.log(err)
        })
          
        }
  };

  // state = {
  //   name: "Unknown",
  //   balance: 0,
  //   egg: 0,
  //   clickValue: 1,
  //   profitPerHour: 0,
  //   screenview: "EGG",
  //   upgrades: Array(),
  //   DailyCombo: Array()
  // };

  var comboClassNames = new Map()
  comboClassNames.set(0, "unknown_pic")
  comboClassNames.set(1, "unknown_pic")
  comboClassNames.set(2, "unknown_pic")

  userData.DailyCombo.forEach((DC:any, i:number) => {
    var DT = SkillsUpgrades.filter( function(data){ return data.id === DC });
    if (DT[0]) {
      comboClassNames.set(i, DT[0].id)
    }
  })

  var currentView: any

  var ff = new Map()
  ff.set("first", "MU")
  ff.set("second", "MU")
  ff.set("third", "MU")

  currentView = SkillsUpgrades.filter(function(data){ return data.id.match(ff.get(ModalView.mainView))})
  currentView.forEach((Ob: any) => {
    // var levelRaw = userData.upgrades.filter(function(data:any) { return data.id === Ob["id"]} )
    var levelRaw: any = (Object.entries(userData.upgrades).filter(([k, v]) => k === Ob["id"]));
    var level = 0
    
    if (levelRaw.length !== 0) {
      level = levelRaw[0][1]
    }
    var comboProfit_value: string
    var comboPrice_value: string
    var comboPrice_text = ''
    if (level < Ob["levels"].length) {
      comboProfit_value = `+ ${format_decs(Ob["levels"][level]["profit"])}`
      comboPrice_value = `${format_decs(Ob["levels"][level]["price"])}`
    } else {
      var comboProfit_num = 0
      Ob["levels"].forEach((DC:any) => {
        comboProfit_num+=DC.profit
      })

      comboProfit_value = `${format_decs(comboProfit_num)}`
      comboPrice_value = ''
      comboPrice_text = 'Достигнут макc. уровень'
    }

    var LevelUpgradeContent = <>
        <div className='combo__lvl_block'>
          <div className='combo__lvl_text'>УР:</div>
          <div className='combo__lvl_value'>{level}</div>
        </div>
        <div className='combo_price__elem'>
          <svg xmlns="http://www.w3.org/2000/svg"></svg>
          <div className='combo_price__value'>{comboPrice_value}</div>
        </div>
      </>
    upgradeData.push(<>
    <div className='combo_elem' {...((comboPrice_value) ? ({onClick:() => openModal(Ob["id"])}) : undefined )}>
      <div className='combo__top_block'>
        <div className={'combo_pic '+Ob["id"]}></div>
        <div className='combo_title__block'>
          <div className='combo_title__text'>
          {Ob["name"]}
          </div>
          <div className='combo_profit__block'>
            <div className='combo_profit__text'>
              Прибыль в час:
            </div>
            <div className='combo_profit__elem'>
              {comboProfit_value && <svg xmlns="http://www.w3.org/2000/svg"></svg>}
              
              <div className='combo_profit__value'>
                {comboProfit_value}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='combo__bottom_block'>
        <div className='combo__bottom_elems'>
          {comboPrice_text && <div className='combo_price__text'>{comboPrice_text}</div>}
          {comboPrice_value && LevelUpgradeContent}
        </div>
      </div>
    </div>

    </>)
  });

  return (
          <>
          <div className='combo_back_block'>
            <div className='combo__block'>
              <div className='combo__block_header'>
                <div className='combo_guess__block'>
                  <div className='combo_guess__header'>
                    <div className='combo_guess__title'>
                      <div className='combo_guess__title_value'>{(userData.DailyCombo).length} / 3</div>
                      <div className='combo_guess__title_text'>КОМБО</div>
                    </div>
                    <div className='combo_guess__value_block'>
                      <svg xmlns="http://www.w3.org/2000/svg"></svg>
                      <div className='combo_guess__value'>
                        +{format(userData.ComboСurrentReward)}
                      </div>
                    </div>
                  </div>
                  <div className='combo_guess__main'>
                    <div {...((comboClassNames.get(0) === "unknown_pic") ? ({className:'combo_guess'}) : ({className:'combo_guess grats'}))}>
                      <svg className={comboClassNames.get(0)} xmlns="http://www.w3.org/2000/svg"></svg>
                    </div>
                    <div {...((comboClassNames.get(1) === "unknown_pic") ? ({className:'combo_guess'}) : ({className:'combo_guess grats'}))}>
                      <svg className={comboClassNames.get(1)} xmlns="http://www.w3.org/2000/svg"></svg>
                    </div>
                    <div {...((comboClassNames.get(2) === "unknown_pic") ? ({className:'combo_guess'}) : ({className:'combo_guess grats'}))}>
                      <svg className={comboClassNames.get(2)} xmlns="http://www.w3.org/2000/svg"></svg>
                    </div>
                  </div>
                </div>
                <div className="combo_balance__block">
                  <div className="balance__block">
                    <svg xmlns="http://www.w3.org/2000/svg"></svg>
                    <div id="balance__value" className="balance__value">{format(balance)}</div>
                  </div>
                </div>
                <div className="upgrade_menu">
                  <div {...(ModalView.mainView === "first") ? ({className: "upgrade_menu_item care active"}) : ({className: "upgrade_menu_item care", onClick:() => updateMainView('first')})}>
                  <div>MINING</div>
                  </div>
                  <div {...(ModalView.mainView === "second") ? ({className: "upgrade_menu_item skills active"}) : ({className: "upgrade_menu_item skills", onClick:() => updateMainView('second')})}>
                  <div>MAGIC</div>
                  </div>
                  <div {...(ModalView.mainView === "third") ? ({className: "upgrade_menu_item upgrade active"}) : ({className: "upgrade_menu_item upgrade", onClick:() => updateMainView('third')})}>
                  <div>CRYPTO</div>
                  </div>
                </div>
              </div>
              <div className="combo__block_data">
                {upgradeData.map((object:any, i:any) => <>{object}</>)}
              </div>
            </div>
          </div>
          <UpgradeModal
                upgradeID={ModalView.modalView}
                visible={ModalView.modalIsOpen}
                userData={userData}
                state={ModalView}
                onSubmit={submitButton}
                onClose={closeModal} />
          <CongratsCombo visible={ModalView.congratsCombo} comboData={comboData} comboCount={userData.DailyCombo.length} onClose={closeModal}/>
    </>
          )
}


// export default function UPGRADE_SCREEN({updateView}: {updateView: () => void})  {
//     return (
//       <>
//         <div className="placeholder__block">
//           <div className="placeholder__text">
//             Скоро откроется
//           </div>
//         </div>
//         <Menu updateView={updateView} activeScreen={'UPGRADE'}/>
//       </>
//     )
//   }