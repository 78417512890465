import "./Popups.css"



export const BluePopup = ({
    visible, text
}:{visible:boolean, text:any}) => {
    if (!visible) return (<></>)

    return (
      <>
      <div className="popup-modal_wrapper">
        <div className="popup-modal">
            <div className="popup-content">
            <div className="popup-text">
                {text}
            </div>
            <div className="popup-close__button"></div>
            </div>
        </div>
      </div>
      </>
    )
  }