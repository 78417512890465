
import './Congrats.css'



interface CongratsCoinsProps {
    visible: boolean
    onClose: () => void
  }

export const CongratsCoins = ({visible, onClose}:CongratsCoinsProps) => {
    if (!visible) return <></>
    setTimeout(onClose, 500)
    return (
    <>
      <div className='grats_wrap'>
        <div
          className="grats_coin grats_coin--animated grats_coin::after"
          style={{"--coin-x:":"2vw", "--coin-to-y": "-32vw", "--coin-delay": "0s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"3vw", "--coin-to-y": "-20vw", "--coin-delay": "0.04s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"4vw", "--coin-to-y": "-24vw", "--coin-delay": "0.04s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"3vw", "--coin-to-y": "-40vw", "--coin-delay": "0s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"5vw", "--coin-to-y": "-24vw", "--coin-delay": "0.04s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"6vw", "--coin-to-y": "-35vw", "--coin-delay": "0s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"7vw", "--coin-to-y": "-30vw", "--coin-delay": "0s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"16vw", "--coin-to-y": "-26vw", "--coin-delay": "0.05s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"55vw", "--coin-to-y": "-34vw", "--coin-delay": "0s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"59vw", "--coin-to-y": "-30vw", "--coin-delay": "0.04s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"63vw", "--coin-to-y": "-22vw", "--coin-delay": "0s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"67vw", "--coin-to-y": "-35vw", "--coin-delay": "0.06s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"71vw", "--coin-to-y": "-31vw", "--coin-delay": "0.1s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"74vw", "--coin-to-y": "-41vw", "--coin-delay": "0s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"77vw", "--coin-to-y": "-38vw", "--coin-delay": "0.05s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"80vw", "--coin-to-y": "-37vw", "--coin-delay": "0.0s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"85vw", "--coin-to-y": "-20vw", "--coin-delay": "0.04s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"90vw", "--coin-to-y": "-42vw", "--coin-delay": "0.02s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"100vw", "--coin-to-y": "-20vw", "--coin-delay": "0s"} as React.CSSProperties}
        ></div>
         <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"120vw", "--coin-to-y": "-40vw", "--coin-delay": "0s"} as React.CSSProperties}
        ></div>
        <div
          className="grats_coin grats_coin--animated"
          style={{"--coin-x:":"150vw", "--coin-to-y": "-30vw", "--coin-delay": "0.04s"} as React.CSSProperties}
        ></div>
      </div>
  </>
      )
  }