import { useState } from 'react'
import './Cypher.css'
import '../EGG/Egg.css'
import '../TASKS/Tasks.css'
import '../../css/Placeholder.css'
import { CongratsCoins } from '../congrats/congrats_component'
import { format } from '../utils'
import { api } from '../../App'
import { TGData, TG } from "../../telegramContext"


interface DailyCypherprops {
    userData: any,
    doneCypher: (res: any) => void,
    openCongrats: () => void,
}

export default function DailyCypher({userData, doneCypher, openCongrats}:DailyCypherprops): JSX.Element  {
    const upgradeData = Array()
    const [ScreenView, setScreenView] = useState({
      modalIsOpen: false,
      modalView: '',
      ScreenView: ''
    })
    const [gratsView, setGratsView] = useState(false)

    const openModal = (modalView:string) => {
        setScreenView({
            modalIsOpen: true,
            modalView: modalView,
            ScreenView: ''
      })
    };
  
    const closeScreenModal = () => {
        setScreenView({
            modalIsOpen: false,
            modalView: '',
            ScreenView: ''
        })
    };

    const openScreen = (screen:string) => {
        setScreenView({
            modalIsOpen: false,
            modalView: '',
            ScreenView: screen
        })
    };

    const closeCongrats = () => {
        setGratsView(false)
    };


    const closeCypherWrapper = (res: any) => {
        setTimeout(
            () => doneCypher(res)
            , 1000)
        setTimeout(
            () => openCongrats()
            , 800)
    };

    const [guessText, setGuessText] = useState('');
    const [sendReqButton, setSendReqButton] = useState(false);
    const [guess, setGuess] = useState('');


    const checkDailyCypher = () => {
        console.log(guessText)
        api.post("/api/task/verify", {
            "filter": {
              "id": TGData.id
            },
            "payload": {
              "block_id":"block_default",
              "task_id": "task_dailycypher",
              "answer": guessText,
              "balance_increase": userData.sync_earnings
            }
            }).then( res => {
              console.log(res.data)
              if (res.data.success) {
                setGuess("checked")
                setGratsView(true)
                closeCypherWrapper(res.data)
                // setGuessStatus('checked')
                // addTaskDone('task_guess')
              } else {
                // setGuessStatus('failed')
                setGuess("failed")
              }
              
            }).catch( err => {
                console.log(err)
                setGuess("failed")
                closeCypherWrapper("error")
            })
    }


    function guesschangefunc(event:any) {

        setGuessText(event.target.value)
        if (event.target.value.length === 0) {
            setSendReqButton(false)
            setGuess('')
        } else {
            setSendReqButton(true)
        }
      }

    return (
        <>
            <div className='cypher__block'>
                <div className='cypher__elems'>
                    <input className='guessInput' placeholder='Введите кодовое слово' onChange={guesschangefunc}></input>
                    <div className='cypher_guess_status__elem'>
                        {(guess) ? <><div className={"modalTaskInput_status "+guess}><div className='icon'></div></div></> : <></>}
                    </div>
                    <div className='cypher_guess__elem'>
                        
                        <div className='cypher_guess__value_block'>
                            
                            {
                                sendReqButton ? <>
                                    <div className='cypher_guess__value' onClick={checkDailyCypher}>
                                        ПРОВЕРИТЬ
                                    </div>
                                </> :
                                <>
                                <svg xmlns="http://www.w3.org/2000/svg"></svg>
                                    <div className='cypher_guess__value'>
                                        +{format(8888888)}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* <CongratsCoins visible={gratsView} onClose={() => closeCongrats()} /> */}

        </>
    )
}



