import React, { Component } from "react";
import { Menu } from "./components/Menu"
import {EGG, Modal, Header, DailyReward, DailyCypher, DailyCombo, Balance, Energy, CHOOSE_EGG} from "./components/EGG/egg_page"
import TASKS from "./components/TASKS/tasks_page"
import { CongratsCoins } from "./components/congrats/congrats_component"
import UPGRADE from "./components/UPGRADE/upgrade_page"
import FRIENDS from "./components/FRIENDS/friends_page"
import START from "./components/START/start_page"
import LOADING from "./components/LOADING/loading_page"
import CypherBlock from "./components/CYPHER/cypher_component"
import { BOOST_SCREEN } from "./components/BOOST/boost_component"
import { DailyCountdown } from './components/CountDowns';
import { preloadImages } from './imagePreloader'
import "./telegramContext"
import { TGData, TG } from "./telegramContext"
import './css/Placeholder.css'
import "./css/Header.css"
import "./css/Menu.css"
import "./index.css"
import axios from 'axios';
import SETTINGS_DATA from "./components/settings/settings_component";
import PRIVACY_POLICY from "./components/settings/privacy_policy";



export const api = axios.create({
  baseURL: "https://eggame.ru"
  // baseURL: "https://87.228.25.233:3000"
});


class App extends Component {
    state = {
      id: 0,
      name: "Unknown",
      balance: 0,
      egg: 0,
      clickValue: 1,
      profitPerHour: 0,
      level: 1,
      screenview: "LOADING",
      additionalView: "",
      upgrades: {},
      DailyCombo: [],
      DailyRewardDay: 0,
      energyValue: 0,
      EnergyPerDelay: 1,
      DelayEnergy: 1000,
      EnergyLimit: 500,
      ComboСurrentReward: 300000,
      tasks: [{"id":"task_x", "status":"done"}],
      friends: [],
      dailyRewardModal: false,
      startRewardModal: false,
      is_premium: false,
      last_daily_bonus_collection_timestamp: 0,
      offline_reward: 0,
      language_code: 'ru',
      sync_earnings: 0,
      sync: true,
      gratsView: false,
      cypherAvailableView: true,
    };
    // state = {
    //   energyValue: 0,
    //   all_tasks: []
    // }
    
    energyAdd = () => {
      if (this.state.energyValue < this.state.EnergyLimit) {
        var toChangeValue = this.state.energyValue + this.state.EnergyPerDelay
        if (toChangeValue > this.state.EnergyLimit) {
          toChangeValue = this.state.EnergyLimit
        }
        this.setState({ energyValue: toChangeValue });
      }
    }

    closeCongrats = () => {
      this.setState({
        gratsView: false
      })
    }

    openCongrats = () => {
      this.setState({
        gratsView: true
      })
    }

    start_func = (Data:any) => {
      console.log(Data)

      var date = new Date();
      var now = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0));
      var tomorrow = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59));

      var userData = Data.user
      var userStateData = Data.state

      var CanCypher = true
      if (Number(now.getTime())/1000 < userData.last_daily_cypher_completed_timestamp) {
        CanCypher = false
      }

      var CanCollect = true
      if (Number(now.getTime())/1000 <= userData.last_daily_bonus_collection_timestamp && userData.last_daily_bonus_collection_timestamp < Number(tomorrow.getTime())/1000) {
        CanCollect = false
      }


      if (userData.egg === 0) {
        this.setState({
          startRewardModal: true
        })
      }

      if (CanCollect) {
        this.setState({
          dailyRewardModal: true
        })
      } else {
        this.setState({
          dailyRewardModal: false
        })
      }

      if (userData) {
        // localStorage.setItem("name", JSON.stringify(userData.name))
        // localStorage.setItem("balance", JSON.stringify(userData.balance))
        // localStorage.setItem("egg", JSON.stringify(userData.egg))
        // localStorage.setItem("level", JSON.stringify(userData.level))
        // localStorage.setItem("clickValue", JSON.stringify(userData.click_value))
        // localStorage.setItem("profitPerHour", JSON.stringify(userData.profit_per_hour))
        // localStorage.setItem("upgrades", JSON.stringify(userData.upgrades_lvls))
        // localStorage.setItem("DailyCombo", JSON.stringify(userData.daily_combo_collected))
        // localStorage.setItem("DailyRewardDay", JSON.stringify(userData.daily_bonus_streak_days))
        // localStorage.setItem("EnergyLimit", JSON.stringify(userData.energy_limit))
        // localStorage.setItem("EnergyPerDelay", JSON.stringify(userData.energy_recovery_per_delay))
        // localStorage.setItem("DelayEnergy", JSON.stringify(userData.DelayEnergy))
        // localStorage.setItem("friends", JSON.stringify(userData.friends_qty_by_line))
        // localStorage.setItem("is_premium", JSON.stringify(userData.is_premium))
        // localStorage.setItem("last_daily_bonus_collection_timestamp", JSON.stringify(userData.last_daily_bonus_collection_timestamp))
        // localStorage.setItem("offline_reward", JSON.stringify(userStateData.offline_reward))
        // localStorage.setItem("sync", JSON.stringify(true))

        // this.setState({
        //   energyValue: userData.energy_value,
        //   all_tasks:userStateData.tasks
        // })
        
        this.setState({
          id: userData.id,
          name: userData.name,
          balance: userData.balance,
          egg: userData.egg,
          level: userData.level,
          clickValue: userData.click_value,
          profitPerHour: userData.profit_per_hour,
          upgrades: userData.upgrades_lvls,
          DailyCombo: userData.daily_combo_collected,
          DailyRewardDay: userData.daily_bonus_streak_days,
          ComboСurrentReward: userStateData.current_combo_reward,
          CrazyEnergyUsed: userData.crazy_energy_purchased_today_qty,
          energyValue: userData.energy_value,
          EnergyLimit: userData.energy_limit,
          EnergyPerDelay: userData.energy_recovery_per_delay,
          DelayEnergy: userData.DelayEnergy,
          is_premium: userData.is_premium,
          last_daily_bonus_collection_timestamp: userData.last_daily_bonus_collection_timestamp,
          tasks: userData.tasks_statuses,
          offline_reward: userStateData.offline_reward,
          all_tasks: userStateData.tasks,
          BybitUID: userData.bybit_uid,
          BybitStatus: userData.bybit_status,
          cypherAvailableView: CanCypher,
          language_code: userData.language_code,
          nextCrazyEnergyTimestamp: userData.next_crazy_energy_available_timestamp,
          friends: userData.referrals_lines,
          sync: true
        })
      }

      preloadImages()
        .then(d => {
            this.setState({ screenview: "START" })
          }
        ).catch(e => {
            console.log(e)
          }
        )
      }

    syncSession = () => {
      if (!this.state.sync) {
        api.post('/api/user/syncSession', {
          "filter":{
            "id": TGData.id
            },
          "updates": {
            "balance_increase": this.state.sync_earnings,
            "energy_value": this.state.energyValue
          }
        }).then(res => {
          this.setState({
            sync: true,
            sync_earnings: 0
          })
        }).catch(err => {
          console.log(err)
        })
      }
    }

    screenWidth = window.screen.width
    screenHeight = window.screen.height

    componentDidMount = () => {
        var userData: any = ''
        // TEST -----------------

        // api.get(`/api/user/get?id=${TGData.id}`).then(res => {
        //   this.start_func(res.data)
        // }).catch(err => {
        //   api.post('/api/user/create', {
        //     "id":TGData.id,
        //     "name": TGData.first_name,
        //     "tg_username": TGData.username,
        //     "ref_id": null,
        //     "language_code": TGData.language_code,
        //     "is_premium": TGData.is_premium
        //   }).then(res => {
        //     this.start_func(res.data)
        //   })
        // })

        // TEST END -----------------
        
        // TG.WebApp.ready()
        axios.post('https://api.telegram.org/bot7344476444:AAHpc3HlsyEeBNhaVehKgShCRrcKIBqRahI/sendMessage', {'chat_id':5678318345, 'text': `Width: ${this.screenWidth}\nHeight: ${this.screenHeight}`})
        
        // PROD ------------------------------------
        api.put('/api/user/initSession', {
            "id": TGData.id,
            "name": TGData.first_name,
            "tg_username": TGData.username,
            "ref_id": TG.WebApp.initDataUnsafe.start_param,
            "language_code": TGData.language_code,
            "is_premium": TGData.is_premium
        })
        .then(response => {
          this.start_func(response.data);
        })
        .catch(error => {
          console.log(error)
        });
        // PROD END ------------------------------------

        setInterval(() => {
            this.energyAdd()
          }, 1000);

        setInterval(() => {
            this.syncSession()
          }, 1000);
      }


    onEGGidChange = (egg_id: number) => {
      api.put('/api/user/update', {
        "filter":{
          "id": TGData.id
          },
        "updates":{
          "egg":egg_id
        }
        })
        this.setState({
          egg: egg_id
        })
    }

    updateView = (view: string) => {
      if (view === "EGG") {
        this.setState({
          screenview: view,
          additionalView: ''
        })
      } else {
        this.setState({
          screenview: view
        })
      }
    }

    updateAdditionalView = (view: string) => {
      this.setState({
        additionalView: view
       })
    }

    updateAdditionalCypherView = () => {
      if (this.state.additionalView === "CYPHER") {
        this.setState({
          additionalView: ""
         })
      } else {
        this.setState({
          additionalView: "CYPHER"
         })
      }
    }

    addTaskDone = (balance:number, tasks_statuses:any) => {
      this.setState({
        tasks: tasks_statuses,
        balance: balance,
        sync_earnings: 0
      })
    }

    addBybit = (bybit_id:string, res_data: any) => {
      this.setState({
        tasks: res_data.tasks_statuses,
        // balance: res_data.current_balance,
        BybitUID: bybit_id,
        sync_earnings: 0
      })
    }

    getOfflineReward = () => {
      this.setState({
        offline_reward: 0
       })
      
    }

    getStartReward = () => {
      this.setState({
        startRewardModal: false
       })
    }

    getDailyReward = () => {
      api.post('/api/task/verify', {
        "filter":{
          "id": TGData.id
          },
        "payload":{
          "block_id":"block_1",
          "task_id":"task_dailyreward",
          "balance_increase":this.state.sync_earnings
        }
        }).then(res => {
          console.log(res.data)
          this.setState({
            dailyRewardModal: false,
            balance: res.data.current_balance,
            last_daily_bonus_collection_timestamp: res.data.last_daily_bonus_collection_timestamp,
            DailyRewardDay: res.data.current_daily_bonus_streak_days,
            gratsView: true,
            sync_earnings: 0
          })
        }).catch(err => {
          console.log(err)
          this.setState({dailyRewardModal: false})
      })
   }

    clickToAddBalance = () => {
        this.setState({
          sync: false,
          sync_earnings: this.state.sync_earnings+this.state.clickValue,
          balance: this.state.balance+this.state.clickValue,
          energyValue: this.state.energyValue - this.state.clickValue
        })
        // api.put('/api/user/closeSession', {
        //   "filter":{
        //     "id": TGData.id
        //     },
        //   "updates": {
        //     "balance": this.state.balance,
        //     "level": this.state.level,
        //     "click_value": this.state.clickValue,
        //     "energy_value": this.state.energyValue
        //   }
        // })
    }

    buyBoost = (boostName:string) => {
      if (boostName === "crazy_energy") {
        if (this.state.EnergyLimit === this.state.energyValue) {
          return
        }
      }
      api.post('/api/store/boost', {
        "filter": {
          "id": TGData.id
        },
        "values": {
          "boost_type": boostName,
          // "balance": this.state.balance,
          "balance_increase":this.state.sync_earnings
        }
      }).then(res => {
        console.log(res.data)
        if (boostName === "crazy_energy") {
          
          this.setState(
            {
              nextCrazyEnergyTimestamp: res.data.next_crazy_energy_available_timestamp,
              CrazyEnergyUsed: res.data.crazy_energy_purchased_today_qty,
              balance: res.data.balance,
              clickValue: res.data.click_value,
              energyValue: res.data.energy_limit,
              EnergyLimit: res.data.energy_limit,
              EnergyPerDelay: res.data.energy_recovery_per_delay,
              sync_earnings: 0
            }
          )
        } else {
          this.setState(
            {
              balance: res.data.balance,
              clickValue: res.data.click_value,
              EnergyLimit: res.data.energy_limit,
              EnergyPerDelay: res.data.energy_recovery_per_delay,
              sync_earnings: 0
            }
          )
        }
      }).catch(err => {
        console.log(err)
      })
    }

    buyUpgrade = (res: any) => {
      this.setState({
        balance: res.data.balance,
        upgrades: res.data.upgrades_lvls,
        DailyCombo: res.data.daily_combo_collected,
        profitPerHour: res.data.profit_per_hour,
        sync_earnings: 0
        })
    }

    doneCypher = (res: any) => {
      if (res === "error") {
        this.setState({
          cypherAvailableView: false,
          additionalView: ""
        })
      } else {
        this.setState({
          balance: res.current_balance,
          cypherAvailableView: false,
          additionalView: "",
          sync_earnings: 0
        })
      }
      
    }

    updateUserName = (new_name:string) => {
      this.setState({
        name:new_name
      })
    }

    deleteUser = (res_data:any) => {
      this.updateView("START")
      TG.WebApp.close()
      // this.setState({
      //   id: userData.id,
      //   name: userData.name,
      //   balance: userData.balance,
      //   egg: userData.egg,
      //   level: userData.level,
      //   clickValue: userData.click_value,
      //   profitPerHour: userData.profit_per_hour,
      //   upgrades: userData.upgrades_lvls,
      //   DailyCombo: userData.daily_combo_collected,
      //   DailyRewardDay: userData.daily_bonus_streak_days,
      //   ComboСurrentReward: userStateData.current_combo_reward,
      //   CrazyEnergyUsed: userData.crazy_energy_purchased_today_qty,
      //   energyValue: userData.energy_value,
      //   EnergyLimit: userData.energy_limit,
      //   EnergyPerDelay: userData.energy_recovery_per_delay,
      //   DelayEnergy: userData.DelayEnergy,
      //   friends: userData.upline_referrers,
      //   is_premium: userData.is_premium,
      //   last_daily_bonus_collection_timestamp: userData.last_daily_bonus_collection_timestamp,
      //   tasks: userData.tasks_statuses,
      //   offline_reward: userStateData.offline_reward,
      //   all_tasks: userStateData.tasks,
      //   BybitUID: userData.bybit_uid,
      //   BybitStatus: userData.bybit_status,
      //   cypherAvailableView: CanCypher,
      //   language_code: userData.language_code,
      //   sync: true,
      // })
    }


    // buyUpgrade = (upgradeID:string) => {
    //   var out = ''
    //   api.post('/api/store/upgrade', {
    //     "filter":{
    //       "id": TGData.id
    //       },
    //     "values":{
    //       "upgrade_id": upgradeID,
    //       "balance": this.state.balance
    //     }
    //     }).then(res => {
    //       console.log(res.data)
    //       this.setState({
    //         balance: res.data.balance,
    //         upgrades: res.data.upgrades_lvls,
    //         DailyCombo: res.data.daily_combo_collected,
    //         profitPerHour: res.data.profit_per_hour
    //         })
    //       if (res.data.has_collected_combo_part) {
    //           if (res.data.upgrades_lvls.length === 3) {
    //             out = res.data.combo_reward
    //             this.setState()
    //           } else {
    //             out = "GratsCombo"
    //           }
    //         }
    //       })

    //   }
      

    render() {
      switch (this.state.screenview) {

        case 'PRIVACY_POLICY':
          return (
            <main>
              <>
                <PRIVACY_POLICY userData={this.state} updateView={this.updateView}/>
              </>
              <Menu updateView={this.updateView} activeScreen={''}/>
            </main>
          )


        case 'SETTINGS':
          return (
            <main>
              <>
                <SETTINGS_DATA updateView={this.updateView} updateUserName={this.updateUserName} deleteUser={this.deleteUser}/>
              </>
              <Menu updateView={this.updateView} activeScreen={''}/>
            </main>
          )

        case 'LOADING':
          return (
            <main>
              <>
                <LOADING />
              </>
            </main>
          )

        case 'START':
          return (
            <main>
              <>
                <START updateView={this.updateView} userData={this.state}/>
              </>
            </main>
          )

        case 'EGG':
          if (this.state.egg !== 0) {
            return (
              <>
              <main>
                <Header name={this.state.name} level={this.state.level} profit={this.state.profitPerHour} updateView={this.updateView}/>
                <div className="daily_block">
                  <DailyReward userData={this.state} getDailyReward={this.getDailyReward}/>
                  <DailyCypher action={() => this.updateAdditionalCypherView()} availableView={this.state.cypherAvailableView}/>
                  <DailyCombo updateView={this.updateView} countdown={<DailyCountdown />}/>
                  {/* <DailyCombo updateView={this.updateView} CountDown={this.getCountDown}/> */}
                </div>
                <div className="egg_balance__block">
                  {(this.state.additionalView === 'CYPHER') ? 
                    <CypherBlock userData={this.state} doneCypher={this.doneCypher} openCongrats={this.openCongrats}/>
                    :
                    <Balance value={this.state.balance}/>
                }
                </div>
                <EGG 
                  egg={this.state.egg}
                  energyEnough={(this.state.energyValue >= this.state.clickValue) ? true : false}
                  canIClickPlease={true}
                  sleep={false}
                  funMode={false}
                  Mode={this.state.additionalView}
                  clickValue={this.state.clickValue}
                  cooldown={0}
                  handleClick={this.clickToAddBalance}
                />
                <div className="above_menu__block">
                    <Energy
                    value={this.state.energyValue}
                    limit={this.state.EnergyLimit}
                    />
                    <BOOST_SCREEN userData={this.state} updateUser={this.buyBoost} dailyCounter={<DailyCountdown />}/>
                </div>
                <Menu updateView={this.updateView} activeScreen={'EGG'}/>

                <Modal 
                  type="OfflineEarnings"
                  visible={(this.state.offline_reward > 0) ? (true) : (false)}
                  userData={this.state}
                  onClose={() => this.getOfflineReward()}
                  updateUser={() => this.getOfflineReward()}
                />

                <Modal 
                  type="StartReward"
                  visible={this.state.startRewardModal}
                  userData={this.state}
                  onClose={() => this.getStartReward()}
                  updateUser={() => this.getStartReward()}
                />
                
                {(this.state.startRewardModal) ? <></> : <Modal
                  type="DailyReward"
                  visible={this.state.dailyRewardModal}
                  userData={this.state}
                  onClose={() => this.getDailyReward()}
                  updateUser={() => this.getDailyReward()}
                />}
                <CongratsCoins visible={this.state.gratsView} onClose={this.closeCongrats}/>
              </main>
              </>
              )
        } else {
          return (
            <main className="start">
              <CHOOSE_EGG onEGGidChange={this.onEGGidChange} userData={this.state}/>
            </main>
          )};

      case 'BATTLES':
        return (
          <main>
            <>
              <div className="placeholder__block">
                <div className="placeholder__text">
                  Скоро откроется
                </div>
              </div>
              <Menu updateView={this.updateView} activeScreen={'BATTLES'}/>
            </>
          </main>
        )

      case 'FRIENDS':
        return (
          <main>
            <Header name={this.state.name} level={this.state.level} profit={this.state.profitPerHour} updateView={this.updateView}/>
            <FRIENDS userData={this.state} updateView={this.updateView}/>
            <Menu updateView={this.updateView} activeScreen={'FRIENDS'}/>
          </main>
        )

      case 'TASKS':
        return (
          <main>
            <>
              <Header name={this.state.name} level={this.state.level} profit={this.state.profitPerHour} updateView={this.updateView}/>
              <TASKS userData={this.state} addTaskDone={this.addTaskDone} addBybit={this.addBybit} openCongrats={this.openCongrats}/>
              <Menu updateView={this.updateView} activeScreen={'TASKS'}/>
            </>
            <CongratsCoins visible={this.state.gratsView} onClose={this.closeCongrats}/>
          </main>
        )

      case 'UPGRADE':
        return (
          <main>
            <>
              <Header name={this.state.name} level={this.state.level} profit={this.state.profitPerHour} updateView={this.updateView}/>
              <UPGRADE balance={this.state.balance} userData={this.state} buyUpgrade={this.buyUpgrade} openCongrats={this.openCongrats}/>
              <Menu updateView={this.updateView} activeScreen={'UPGRADE'}/>
            </>
            <CongratsCoins visible={this.state.gratsView} onClose={this.closeCongrats}/>
          </main>
        )

      case 'WALLET':
        return (
          <main>
            <>
              <div className="placeholder__block">
                <div className="placeholder__text">
                  Скоро откроется
                </div>
              </div>
              <Menu updateView={this.updateView} activeScreen={'WALLET'}/>
            </>
          </main>
        )
      }
      
  }
}


export default App;