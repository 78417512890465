import { Component } from "react"
import React from "react"
import "../css/Menu.css"
import "../index.css"



export class Menu extends Component<{activeScreen: string, updateView: (view: string) => void}, {}> {

    classEGG: any
    classBATTLES: any
    classFRIENDS: any
    classTASKS: any
    classUPGRADE: any
    classWALLET: any

    render() {
        if (this.props.activeScreen === 'EGG') {
            this.classEGG = {name:"main_menu__Item active", onclick:{}}
        } else {
            this.classEGG = {name:"main_menu__Item", onclick:{onClick:() => this.props.updateView('EGG')}}
        };
        if (this.props.activeScreen === 'BATTLES') {
            this.classBATTLES = {name:"main_menu__Item active", onclick:{}}
        } else {
            this.classBATTLES = {name:"main_menu__Item", onclick:{onClick:() => this.props.updateView('BATTLES')}}
        };
        if (this.props.activeScreen === 'FRIENDS') {
            this.classFRIENDS = {name:"main_menu__Item active", onclick:{}}
        } else {
            this.classFRIENDS = {name:"main_menu__Item", onclick:{onClick:() => this.props.updateView('FRIENDS')}}
        };
        if (this.props.activeScreen === 'TASKS') {
            this.classTASKS = {name:"main_menu__Item active", onclick:{}}
        } else {
            this.classTASKS = {name:"main_menu__Item", onclick:{onClick:() => this.props.updateView('TASKS')}}
        };
        if (this.props.activeScreen === 'UPGRADE') {
            this.classUPGRADE = {name:"main_menu__Item active", onclick:{}}
        } else {
            this.classUPGRADE = {name:"main_menu__Item", onclick:{onClick:() => this.props.updateView('UPGRADE')}}
        };
        if (this.props.activeScreen === 'WALLET') {
            this.classWALLET = {name:"main_menu__Item active", onclick:{}}
        } else {
            this.classWALLET = {name:"main_menu__Item", onclick:{onClick:() => this.props.updateView('WALLET')}}
        };

    return (
        <div className="main_menu__elem">
            <div className={this.classEGG.name} {...this.classEGG.onclick}>
                <svg className="main_menu__Item_img mining" xmlns="http://www.w3.org/2000/svg"></svg>
                <div>EGG</div>
            </div>
            <div className={this.classBATTLES.name} {...this.classBATTLES.onclick}>
                <svg className="main_menu__Item_img battles" xmlns="http://www.w3.org/2000/svg"></svg>
                <div>BATTLE</div>
            </div>
            <div className={this.classUPGRADE.name} {...this.classUPGRADE.onclick}>
                <svg className="main_menu__Item_img improvements" xmlns="http://www.w3.org/2000/svg"></svg>
                <div>UPGRADE</div>
            </div>
            <div className={this.classTASKS.name} {...this.classTASKS.onclick}>
                <svg className="main_menu__Item_img assignments" xmlns="http://www.w3.org/2000/svg"></svg>
                <div>TASKS</div>
            </div>
            <div className={this.classFRIENDS.name} {...this.classFRIENDS.onclick}>
                <svg className="main_menu__Item_img friends" xmlns="http://www.w3.org/2000/svg"></svg>
                <div>FRIENDS</div>
            </div>
            <div className={this.classWALLET.name} {...this.classWALLET.onclick}>
                <svg className="main_menu__Item_img wallet" xmlns="http://www.w3.org/2000/svg"></svg>
                <div>WALLET</div>
            </div>
        </div>
    )
    }
}
