import React, { useState, useEffect } from "react";



export const CrazyCountdown = ({nextTime}) => {
    var targetDate = ''
    if (!nextTime) {
        const now = new Date()
        now.setHours(23, 59, 59);
        console.log(now)
        targetDate = now.getTime()
    } else {
        targetDate = nextTime
    }
    
    
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate*1000) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
        return timeLeft;
    };
    
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });
    
    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval] && timeLeft[interval] !== 0) {
            return;
        }
        timerComponents.push(
            <span key={interval}>
                {String(timeLeft[interval]).padStart(2, "0")}
                {["hours", "minutes"].includes(interval) ? ":" : ""}
            </span>
        );
    });
    return (
        <div>
            {timerComponents.length ? timerComponents : ''}
        </div>
    );

}



export const DailyCountdown = ({}) => {
    const now = new Date();
    const tomorrow = new Date();
    var date = new Date();
    tomorrow.setDate(now.getDate() + 1)
    tomorrow.setHours(0, 0, 0, 0);


    // var now = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0));
    // var tomorrow = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59));

    const targetDate = tomorrow.getTime()

    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 60000);
        return () => clearTimeout(timer);
    });

    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval] && timeLeft[interval] !== 0) {
            return;
        }
        timerComponents.push(
            <span key={interval}>
                {String(timeLeft[interval]).padStart(2, "0")}
                {interval === "hours" ? ":" : ""}
            </span>
        );
    });
    return (
        <div>
            {timerComponents.length ? timerComponents : <span>00:00</span>}
        </div>
    );
};
