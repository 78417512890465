export const TG = window.Telegram;

// function TggF(theObject) {
//     if (TG.WebApp.initDataUnsafe !== {}) {
//         theObject = TG.WebApp.initDataUnsafe.user;
//     }
//   }
  
// const out = {
//     "id":5678318345,
//     "first_name":"Сергей",
//     "username":"serg",
//     "language_code":"ru",
//     "is_premium":true,
//     "dev":true
// };

// TggF(out)

export const TGData = TG.WebApp.initDataUnsafe.user
