import { Component } from 'react'
import './Start.css'
import '../../css/Placeholder.css'



const StartScreen = ({screenView}:{screenView: number}) => {
    var descriptions = [
      <>Это игра, в которой вы развиваете своего EGG,<br/>зарабатываете игровые монеты и, в конечном<br/>итоге, получаете настоящий токен с реальной<br/>ценностью на бирже. Более того, вы сможете<br/>зарабатывать уже существующую на бирже<br/>монету TON во время игры и использовать ее по<br/>своему усмотрению, не дожидаясь листинга<br/>игровой монеты EGG.</>,
      "Основные способы заработка игровой монеты EGG включают: кликать по экрану, сражаться и прокачивать своего EGG.",
      "Вы можете прокачивать своего EGG, чтобы повысить пассивный почасовой доход и начать зарабатывать прямо во время игры",
      <>Приглашайте друзей присоединиться к игре, и<br/>вы будете зарабатывать процент от их доходов.<br/>Вам начисляется процент от ваших друзей до<br/>целых 10 уровней в глубину. Это позволит вам<br/>быстрее и эффективнее накапливать<br/>виртуальные монеты EGG.</>,
      "Чем дальше вы продвигаетесь в игре, тем больше будет ваш эирдроп токенов EGG и тем больше вы заработаете токенов TON уже во время игры.",
      "Внимательно следите за своим EGG, чтобы он не потерял свои силы и мощность, иначе ваш EGG будет утрачен и вам придется начать сначала.",
    ]

    return (<>
    <div className='start_content__block'>
      <div className={'start_pic pic_'+String(screenView)}></div>
      <div className='start_desc'>
          {descriptions[screenView]}
      </div>
    </div>
    </>)
}



export default class START_DATA extends Component<{updateView: (view: string) => void, userData: any}, {}> {

  state = {
    screenView: 0
  }

  descriptions = [
    <>Это игра, в которой вы развиваете своего EGG,<br/>зарабатываете игровые монеты и, в конечном<br/>итоге, получаете настоящий токен с реальной<br/>ценностью на бирже. Более того, вы сможете<br/>зарабатывать уже существующую на бирже<br/>монету TON во время игры и использовать ее по<br/>своему усмотрению, не дожидаясь листинга<br/>игровой монеты EGG.</>,
    "Основные способы заработка игровой монеты EGG включают: кликать по экрану, сражаться и прокачивать своего EGG.",
    "Вы можете прокачивать своего EGG, чтобы повысить пассивный почасовой доход и начать зарабатывать прямо во время игры",
    <>Приглашайте друзей присоединиться к игре, и<br/>вы будете зарабатывать процент от их доходов.<br/>Вам начисляется процент от ваших друзей до<br/>целых 10 уровней в глубину. Это позволит вам<br/>быстрее и эффективнее накапливать<br/>виртуальные монеты EGG.</>,
    "Чем дальше вы продвигаетесь в игре, тем больше будет ваш эирдроп токенов EGG и тем больше вы заработаете токенов TON уже во время игры.",
    "Внимательно следите за своим EGG, чтобы он не потерял свои силы и мощность, иначе ваш EGG будет утрачен и вам придется начать сначала.",
  ]

  nextScreen = () => {
    if (this.state.screenView === 5) {
      this.props.updateView('EGG')
    } else {
      this.setState({
        screenView: this.state.screenView + 1
      })
    }
  }

  skipScreen = () => {
      this.props.updateView('EGG')
  }

  render() {
    return (
      <>
        <div className="start_elems">

            <div className={'start_pic pic_'+String(this.state.screenView)}></div>

            <div className='start_desc'>
                {this.descriptions[this.state.screenView]}
            </div>

            <div className="start_footer">
                <div className='progress_bar'>
                    <ul id="progressbar">
                    <li {...(this.state.screenView === 0) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 1) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 2) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 3) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 4) ? ({className:"active"}) : ('')}></li>
                    <li {...(this.state.screenView === 5) ? ({className:"active"}) : ('')}></li>
                    </ul>
                </div>
            </div>
            
            <div className="next_button" onClick={() => this.nextScreen()}>ДАЛЕЕ</div>
            {(this.props.userData.egg === 0) ? (<></>) : (<><div className="skip_button" onClick={() => this.skipScreen()}>ПРОПУСТИТЬ</div></>)}

        </div>
    </>
    )
  }
}