import { Component, useState } from 'react'
import './Privacy.css'


const texts: any = {
    "ru":<>
    <h1>
    Политика конфиденциальности для мини приложений в телеграм
    </h1>
    <p>
        <em>
        Сентябрь 01, 2024
        </em>
    </p>
    <small>
    Эта страница информирует вас о наших политиках в отношении сбора, использования и раскрытия личной информации, когда вы используете наше Приложение.
    </small>
    <h1>
    1. Key Concepts
    </h1>
    <p>
    1.1. Разработчик: Физическое или юридическое лицо, которое публикует приложение на платформе и использует его в соответствии с условиями платформы. Информацию о Разработчике, такую как имя и другие данные, можно найти в разделе "Информация о разработчике" в настройках приложения.
    </p>
    <p>
    1.2. Приложение: Программный сервис, опубликованный Разработчиком на платформе, включенный в каталог приложений.
    </p>
    <p>
    1.3. Пользователь: Физическое лицо, которое зарегистрировалось и использует Приложение.
    </p>
    <p>
    1.4. Политика: Данная Политика конфиденциальности описывает, как обрабатывается и защищается информация о Пользователях.
    </p>
    <h1>
    2. Общие положения
    </h1>
    <p>
    2.1. Эта Политика является официальным документом Разработчика и определяет порядок обработки и защиты информации о физических лицах, использующих Приложения.
    </p>
    <p>
    2.2. Цель данной Политики — обеспечить надлежащую защиту информации о Пользователях, включая их персональные данные, от несанкционированного доступа и раскрытия.
    </p>
    <p>
    2.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о Пользователях, регулируются данной Политикой и другими официальными документами Разработчика, а также применимым законодательством.
    </p>
    <p>
    2.4. Текущая версия данной Политики, которая является публичным документом, доступна любому Пользователю в интернете по гиперссылке.
    </p>
    <p>
    2.5. Разработчик имеет право вносить изменения в эту Политику. При внесении изменений Разработчик публикует новую версию Политики и обновляет дату "Вступления в силу" в верхней части Политики. Рекомендуется Пользователям регулярно проверять условия данной Политики на предмет изменений. Продолжение использования Приложения Пользователем после внесения изменений в эту Политику свидетельствует о принятии и согласии с такими изменениями.
    </p>
    <p>
    2.6. Используя Приложения, Пользователь соглашается с условиями этой Политики.
    </p>
    <p>
    2.7. Если Пользователь не согласен с условиями данной Политики, использование Приложений должно быть немедленно прекращено.
    </p>
    <h1>
    3. Условия использования Приложений
    </h1>
    <p>
    3.1. Предоставляя услуги по использованию Приложений, Разработчик разумно и добросовестно полагает, что Пользователь:
    <ul>
        <li>Имеет все необходимые права, позволяющие ему начать использовать Приложение.</li>
        <li>Предоставляет достоверную информацию о себе, как это требуется для использования Приложений.</li>
        <li>Понимает, что информация, переданная во время использования Приложений, может стать доступной другим Пользователям и может быть скопирована и распространена ими.</li>
        <li>Понимает, что некоторые виды информации, предоставленные другим Пользователям во время использования Приложений, не могут быть удалены самим Пользователем.</li>
        <li>Ознакомлен с данной Политикой, соглашается с ней и принимает на себя права и обязательства, указанные в ней.</li>
    </ul>
    </p>
    <p>
    3.2. Разработчик не проверяет достоверность информации, полученной (собранной) о Пользователях.
    </p>
    <h1>
    4. Цели обработки информации
    </h1>
    <p>
    Разработчик обрабатывает информацию о Пользователях, включая их персональные данные, для выполнения своих обязательств перед Пользователями в отношении использования Приложения.
    </p>
    <h1>
    5. Состав информации о Пользователях
    </h1>
    <p>
    Разработчик обрабатывает следующую информацию о Пользователях:
    </p>
    <ul>
        <li>Персональные данные, предоставленные с согласия Пользователя и необходимые для использования Приложений.</li>
        <li>Дополнительная информация о Пользователях, предоставленная администрацией платформы с согласия Пользователя.</li>
        <li>Информация, предоставленная Пользователями во время использования Приложений.</li>
        <li>Информация, полученная Разработчиком в результате действий Пользователя при использовании Приложений (например, IP-адреса, файлы cookie, статистика использования приложения).</li>
    </ul>
    <h1>
    6. Обработка персональных данных Пользователя 
    </h1>
    <p>
    6.1. Обработка персональных данных основывается на принципах:
    </p>
    <ul>
        <li>Законности и добросовестности обработки персональных данных.</li>
        <li>Соответствия целей обработки персональных данных целям, заранее определенным и заявленным во время их сбора.</li>
        <li>Соответствия объема и характера обработанных персональных данных заявленным целям обработки.</li>
        <li>Недопустимости объединения баз данных, содержащих персональные данные, для несовместимых целей.</li>
    </ul>
    <p>
    6.1.1. Условия и цели обработки персональных и других данных:
    </p>
    <ul>
        <li>Разработчик обрабатывает персональные и другие данные Пользователя с его согласия для предоставления услуг, связанных с использованием Приложений.</li>
    </ul>
    <p>
    6.1.2. Сбор персональных и других данных:
    </p>
    <ul>
        <li>Персональные данные и информация предоставляются администрацией платформы с согласия Пользователя при первом запуске и/или использовании Приложения.</li>
        <li>Дополнительная информация о Пользователе предоставляется администрацией платформы с согласия Пользователя посредством явного действия (например, нажатия кнопки "Разрешить") при первом запуске и/или использовании Приложения.</li>
        <li>Информация собирается Разработчиком с согласия Пользователя при первом запуске и/или использовании Приложения.</li>
    </ul>
    <p>
    6.1.3. Передача персональных данных:
    </p>
    <ul>
        <li>Персональные данные не передаются третьим лицам, кроме случаев, прямо предусмотренных данной Политикой. Передача персональных данных государственным органам осуществляется в соответствии с законом.</li>
    </ul>
    <p>
    6.2. Информация, такая как имя Пользователя, фото профиля и информация о его использовании, может быть доступна другим Пользователям Приложения.
    </p>
    <p>
    6.3. Персональные данные хранятся в течение всего времени использования Приложения и в течение периода, необходимого в соответствии с действующим законодательством после прекращения использования Приложения.
    </p>
    <h1>
    7. Права и обязанности Пользователей 
    </h1>
    <p>
    7.1. Пользователи имеют право на:
    </p>
    <ul>
        <li>Доступ к информации о себе через Приложения.</li>
        <li>Получение информации от Разработчика о обработке своих персональных данных по запросу.</li>
    </ul>
    <h1>
    8. Меры по защите информации Пользователей 
    </h1>
    <p>
    Разработчик принимает технические и организационные меры для обеспечения защиты персональных данных от несанкционированного или случайного доступа, уничтожения, изменения, блокировки, копирования, распространения, а также от других незаконных действий. Описание этих мер содержится во внутренних регламентах Разработчика.
    </p>
    <h1>
    9. Ограничения Политики
    </h1>
    <p>
    Настоящая Политика не применяется к действиям и ресурсам третьих лиц. Разработчик не несет ответственности за действия третьих лиц, которые получили доступ к информации Пользователя в силу особенностей платформы Приложения, доступной любому пользователю интернета. Пользователям рекомендуется внимательно относиться к объему информации, которую они распространяют.
    </p>
    <h1>
    10. Запросы Пользователей
    </h1>
    <p>
    10.1. Пользователи могут отправлять запросы касательно использования своих персональных данных Разработчику в письменной форме или в виде электронного документа, подписанного квалифицированной электронной подписью.
    </p>
    <p>
    10.2. Запросы должны содержать:
    </p>
    <ul>
        <li>Номер документа удостоверяющего личность Пользователя и данные выдавшего органа.</li>
        <li>Информацию, подтверждающую отношения Пользователя с Разработчиком.</li>
        <li>Подпись Пользователя или его представителя.</li>
    </ul>
    <p>
    10.3. Разработчик рассмотрит и ответит на запрос в течение 30 дней с момента его получения.
    </p>
    <p>
    10.4. Вся корреспонденция является конфиденциальной и не будет разглашена без письменного согласия Пользователя. Персональные данные и другая информация будут использоваться исключительно для ответа на запрос или в соответствии с требованиями закона.
    </p>
    </>,
    "en":<>
    <h1>
    Privacy Policy for Mini Apps in Telegram
    </h1>
    <p>
        <em>
        September 01, 2024
        </em>
    </p>
    <small>
    This page informs you of our policies regarding the collection, use, and disclosure of personal information when you use our Application.    </small>
    <h1>
    1. Key Concepts
    </h1>
    <p>
    1.1. Developer: An individual or legal entity that publishes an application on the platform and uses it in accordance with the platform's terms. Information about the Developer, such as name and other details, can be found in the "Developer Information" section in the application settings.
    </p>
    <p>
    1.2. Application: A software service published by the Developer on the platform, included in the application catalog.
    </p>
    <p>
    1.2. Application: A software service published by the Developer on the platform, included in the application catalog.
    </p>
    <p>
    1.4. Политика: Данная Политика конфиденциальности описывает, как обрабатывается и защищается информация о Пользователях.
    </p>
    <h1>
    2. Общие положения
    </h1>
    <p>
    2.1. Эта Политика является официальным документом Разработчика и определяет порядок обработки и защиты информации о физических лицах, использующих Приложения.
    </p>
    <p>
    2.2. Цель данной Политики — обеспечить надлежащую защиту информации о Пользователях, включая их персональные данные, от несанкционированного доступа и раскрытия.
    </p>
    <p>
    2.3. Отношения, связанные со сбором, хранением, распространением и защитой информации о Пользователях, регулируются данной Политикой и другими официальными документами Разработчика, а также применимым законодательством.
    </p>
    <p>
    2.4. Текущая версия данной Политики, которая является публичным документом, доступна любому Пользователю в интернете по гиперссылке.
    </p>
    <p>
    2.5. Разработчик имеет право вносить изменения в эту Политику. При внесении изменений Разработчик публикует новую версию Политики и обновляет дату "Вступления в силу" в верхней части Политики. Рекомендуется Пользователям регулярно проверять условия данной Политики на предмет изменений. Продолжение использования Приложения Пользователем после внесения изменений в эту Политику свидетельствует о принятии и согласии с такими изменениями.
    </p>
    <p>
    2.6. Используя Приложения, Пользователь соглашается с условиями этой Политики.
    </p>
    <p>
    2.7. Если Пользователь не согласен с условиями данной Политики, использование Приложений должно быть немедленно прекращено.
    </p>
    <h1>
    3. Условия использования Приложений
    </h1>
    <p>
    3.1. Предоставляя услуги по использованию Приложений, Разработчик разумно и добросовестно полагает, что Пользователь:
    <ul>
        <li>Имеет все необходимые права, позволяющие ему начать использовать Приложение.</li>
        <li>Предоставляет достоверную информацию о себе, как это требуется для использования Приложений.</li>
        <li>Понимает, что информация, переданная во время использования Приложений, может стать доступной другим Пользователям и может быть скопирована и распространена ими.</li>
        <li>Понимает, что некоторые виды информации, предоставленные другим Пользователям во время использования Приложений, не могут быть удалены самим Пользователем.</li>
        <li>Ознакомлен с данной Политикой, соглашается с ней и принимает на себя права и обязательства, указанные в ней.</li>
    </ul>
    </p>
    <p>
    3.2. Разработчик не проверяет достоверность информации, полученной (собранной) о Пользователях.
    </p>
    <h1>
    4. Цели обработки информации
    </h1>
    <p>
    Разработчик обрабатывает информацию о Пользователях, включая их персональные данные, для выполнения своих обязательств перед Пользователями в отношении использования Приложения.
    </p>
    <h1>
    5. Состав информации о Пользователях
    </h1>
    <p>
    Разработчик обрабатывает следующую информацию о Пользователях:
    </p>
    <ul>
        <li>Персональные данные, предоставленные с согласия Пользователя и необходимые для использования Приложений.</li>
        <li>Дополнительная информация о Пользователях, предоставленная администрацией платформы с согласия Пользователя.</li>
        <li>Информация, предоставленная Пользователями во время использования Приложений.</li>
        <li>Информация, полученная Разработчиком в результате действий Пользователя при использовании Приложений (например, IP-адреса, файлы cookie, статистика использования приложения).</li>
    </ul>
    <h1>
    6. Обработка персональных данных Пользователя 
    </h1>
    <p>
    6.1. Обработка персональных данных основывается на принципах:
    </p>
    <ul>
        <li>Законности и добросовестности обработки персональных данных.</li>
        <li>Соответствия целей обработки персональных данных целям, заранее определенным и заявленным во время их сбора.</li>
        <li>Соответствия объема и характера обработанных персональных данных заявленным целям обработки.</li>
        <li>Недопустимости объединения баз данных, содержащих персональные данные, для несовместимых целей.</li>
    </ul>
    <p>
    6.1.1. Условия и цели обработки персональных и других данных:
    </p>
    <ul>
        <li>Разработчик обрабатывает персональные и другие данные Пользователя с его согласия для предоставления услуг, связанных с использованием Приложений.</li>
    </ul>
    <p>
    6.1.2. Сбор персональных и других данных:
    </p>
    <ul>
        <li>Персональные данные и информация предоставляются администрацией платформы с согласия Пользователя при первом запуске и/или использовании Приложения.</li>
        <li>Дополнительная информация о Пользователе предоставляется администрацией платформы с согласия Пользователя посредством явного действия (например, нажатия кнопки "Разрешить") при первом запуске и/или использовании Приложения.</li>
        <li>Информация собирается Разработчиком с согласия Пользователя при первом запуске и/или использовании Приложения.</li>
    </ul>
    <p>
    6.1.3. Передача персональных данных:
    </p>
    <ul>
        <li>Персональные данные не передаются третьим лицам, кроме случаев, прямо предусмотренных данной Политикой. Передача персональных данных государственным органам осуществляется в соответствии с законом.</li>
    </ul>
    <p>
    6.2. Информация, такая как имя Пользователя, фото профиля и информация о его использовании, может быть доступна другим Пользователям Приложения.
    </p>
    <p>
    6.3. Персональные данные хранятся в течение всего времени использования Приложения и в течение периода, необходимого в соответствии с действующим законодательством после прекращения использования Приложения.
    </p>
    <h1>
    7. Права и обязанности Пользователей 
    </h1>
    <p>
    7.1. Пользователи имеют право на:
    </p>
    <ul>
        <li>Доступ к информации о себе через Приложения.</li>
        <li>Получение информации от Разработчика о обработке своих персональных данных по запросу.</li>
    </ul>
    <h1>
    8. Меры по защите информации Пользователей 
    </h1>
    <p>
    Разработчик принимает технические и организационные меры для обеспечения защиты персональных данных от несанкционированного или случайного доступа, уничтожения, изменения, блокировки, копирования, распространения, а также от других незаконных действий. Описание этих мер содержится во внутренних регламентах Разработчика.
    </p>
    <h1>
    9. Ограничения Политики
    </h1>
    <p>
    Настоящая Политика не применяется к действиям и ресурсам третьих лиц. Разработчик не несет ответственности за действия третьих лиц, которые получили доступ к информации Пользователя в силу особенностей платформы Приложения, доступной любому пользователю интернета. Пользователям рекомендуется внимательно относиться к объему информации, которую они распространяют.
    </p>
    <h1>
    10. Запросы Пользователей
    </h1>
    <p>
    10.1. Пользователи могут отправлять запросы касательно использования своих персональных данных Разработчику в письменной форме или в виде электронного документа, подписанного квалифицированной электронной подписью.
    </p>
    <p>
    10.2. Запросы должны содержать:
    </p>
    <ul>
        <li>Номер документа удостоверяющего личность Пользователя и данные выдавшего органа.</li>
        <li>Информацию, подтверждающую отношения Пользователя с Разработчиком.</li>
        <li>Подпись Пользователя или его представителя.</li>
    </ul>
    <p>
    10.3. Разработчик рассмотрит и ответит на запрос в течение 30 дней с момента его получения.
    </p>
    <p>
    10.4. Вся корреспонденция является конфиденциальной и не будет разглашена без письменного согласия Пользователя. Персональные данные и другая информация будут использоваться исключительно для ответа на запрос или в соответствии с требованиями закона.
    </p>
    </>,
}

export default class PRIVACY_POLICY extends Component<{userData: any, updateView: (view:string) => void}, {}> {
    
    render() {
        console.log(this.props.userData.language_code)
        return (<>
        <div className='privacy'>
            <span className='privacy-close' onClick={() => this.props.updateView('SETTINGS')}>
            &times;
          </span>
        {texts[this.props.userData.language_code]}
        </div>
        </>)
    }
}
